import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import App from "./App";
import store from "./stores";
import firebase from "./firebase";
import PerfilState from "./context/perfil/perfilState";
import { AppProvider } from "./context/appContext";

// react redux firebase config
const config = {
  userProfile: "users",
  useFirestoreForProfile: true,
};

// react redux firebase props
const props = {
  firebase,
  config: config,
  dispatch: store.dispatch,
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...props}>
      <PerfilState>
        <AppProvider>
          <App />
        </AppProvider>
      </PerfilState>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
