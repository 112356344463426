function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const generateDefaultChartOptions = (chartType = "pie") => ({
  chart: {
    type: chartType,
    options3d: {
      enabled: chartType == "pie",
      alpha: 45,
      beta: 0,
    },
  },
  title: {
    text: "TITULO POR DEFAULT",
  },
  subtitle: {
    text: "SUBTITULO POR DEFAULT",
  },
  plotOptions: {
    pie: {
      innerSize: 100,
      depth: 45,
    },
  },
  series: [
    {
      name: "NOMBRE DE LA COLECCION DE DATOS",
      data: [],
    },
  ],
});

export { capitalize, pad, generateDefaultChartOptions };
