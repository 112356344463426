import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { isNumber } from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import highcharts3d from "highcharts/highcharts-3d";

highcharts3d(Highcharts);

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}

const LinearChart = ({ values }) => {
  return <HighchartsReact highcharts={Highcharts} options={values} />;
};

export default LinearChart;
