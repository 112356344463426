import React, { useEffect, useState } from 'react';
import SelectWithSearch from 'react-select';
import Api from '../../../utils/Api';

const SelectSeccion = (props) => {

  const [lista, setLista] = useState([]);

  useEffect(() => {
    if(props.distrito !== undefined && props.distrito !== null) {
      obtenerLista(props.distrito);
    }
  }, []);

  const obtenerLista = async (idDistrito) => {
    let params = {
      fields: 'id,nombre',
      ordenar: 'nombre.asc'
    };
    if(idDistrito !== undefined && idDistrito !== null && idDistrito !== "") {
      params['distrito'] = idDistrito;
    }
    let merge = {
      ...params,
      ...props.params
    };
    Api.obtenerSecciones(merge)
      .catch(error => {
        if(props.responseErrorCallback !== undefined) {
          props.responseErrorCallback(error);
        } 
      })
      .then(response => {
        if(response === undefined)
          return;
        
        if(response.resultado !== undefined && response.resultado.length) {
          setLista(response.resultado);
        }
      });
  };

  const opciones = () => {
    let opciones = [];
    for (let i in lista) {
      const seccion = lista[i];
      opciones.push({
        value: seccion.id,
        label: seccion.nombre
      });
    }
    return opciones;
  }

  return (
    <SelectWithSearch
      {...props}
      label={props.label !== undefined && props.label !== null ? props.label : "Sección"}
      options={opciones()}
      placeholder={props.placeholder !== undefined && props.label !== null ? props.placeholder : "Seleccione..."}
      onChange={(e) => {
        if(props.onChange !== undefined) {
          let seccion = lista.find(item => item.id === e.value);
          let value = {
            ...e,
            ...seccion
          };
          props.onChange(value);
        }
      }}
    />
  );
}

export default SelectSeccion;
