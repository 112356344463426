import firebase from "firebase/app";
import "firebase/storage";
import "firebase/database";

require("firebase/auth");
require("firebase/firestore");

const config = {
  apiKey: "AIzaSyCSMna4LJ-KnS6j-WdOZC2SbT1Is6jVLLk",
  authDomain: "sire-8ebe1.firebaseapp.com",
  databaseURL: "https://sire-8ebe1.firebaseio.com",
  projectId: "sire-8ebe1",
  storageBucket: "sire-8ebe1.appspot.com",
  messagingSenderId: "802523050932",
  appId: "1:802523050932:web:52df0186ec0e66a70a78e1",
  measurementId: "G-0W0PYRBSBW",
};

firebase.initializeApp(config);
export const db = firebase.firestore();
export const storage = firebase.storage();
export const database = firebase.database();

export default firebase;
