import React, { useEffect, useState, useContext } from "react";
import SelectWithSearch from "react-select";
import PerfilContext from "../../../context/perfil/perfilContext";
import Api from "../../../utils/Api";
import { ROL } from "../../../constants";

const SelectDistrito = (props) => {
  const { perfil } = useContext(PerfilContext);
  const [lista, setLista] = useState([]);

  useEffect(() => {
    obtenerLista();
  }, []);

  const obtenerLista = async () => {
    let params = {
      limite: -1, // Cuando límite es menor que 0 regresa todos los registros
      fields: "id, nombre", // Solo se necesitan id y nombre para el selector
      expand: "distritos", // Regresar todos los distritos hijos
      ordenar: "nombre.asc",
    };
    if (perfil.rol <= ROL.COORDINADOR_FEDERAL) {
      params["tipo"] = 1;
    }
    let merge = {
      ...params,
      ...props.params,
    };
    Api.obtenerDistritos(merge)
      .catch((error) => {
        if (props.responseErrorCallback !== undefined) {
          props.responseErrorCallback(error);
        }
      })
      .then((response) => {
        if (response === undefined) return;

        if (response.resultado !== undefined && response.resultado.length) {
          setLista(response.resultado);
        }
      });
  };

  const opciones = () => {
    let opciones = [];
    if (props.conTodos === true) {
      opciones = [
        {
          label: "TODOS",
          value: "",
        },
      ];
    }

    if (
      perfil.rol <= ROL.COORDINADOR_FEDERAL &&
      (props.mostrarFederal === undefined ||
        (props.mostrarFederal !== undefined && props.mostrarFederal === false))
    ) {
      for (let i in lista) {
        const distFederal = lista[i];
        let grupo = {
          label: `Distrito Federal ${distFederal.nombre}`,
          options: [],
        };
        for (let j in distFederal.distritos) {
          const distLocal = distFederal.distritos[j];
          grupo.options.push({
            label: `Distrito ${distLocal.nombre}`,
            value: distLocal.id,
          });
        }
        opciones.push(grupo);
      }
    } else {
      for (let i in lista) {
        const distrito = lista[i];
        opciones.push({
          value: distrito.id,
          label: `Distrito ${props.mostrarFederal ? "Federal" : ""} ${
            distrito.nombre
          }`,
        });
      }
    }
    return opciones;
  };

  return (
    <SelectWithSearch
      menuShouldScrollIntoView={true}
      {...props}
      label={
        props.label !== undefined && props.label !== null
          ? props.label
          : "Distrito"
      }
      options={opciones()}
      placeholder={
        props.placeholder !== undefined && props.label !== null
          ? props.placeholder
          : "Seleccione..."
      }
      onChange={(e) => {
        if (props.onChange !== undefined) {
          let distrito = null;
          if (perfil.rol <= ROL.COORDINADOR_FEDERAL) {
            encontrar: for (let i in lista) {
              const distFederal = lista[i];
              if (distFederal.id === e.value) {
                distrito = distFederal;
                break encontrar;
              }
              for (let j in distFederal.distritos) {
                const distLocal = distFederal.distritos[j];
                if (distLocal.id === e.value) {
                  distrito = distLocal;
                  break encontrar;
                }
              }
            }
          } else {
            for (let i in lista) {
              const _distrito = lista[i];
              if (_distrito.id === e.value) {
                distrito = _distrito;
              }
            }
          }
          let value = {
            ...e,
            ...distrito,
          };
          props.onChange(value);
        }
      }}
    />
  );
};

export default SelectDistrito;
