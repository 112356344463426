import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Api from '../../../utils/Api';

const MultiSelectSeccion = (props) => {

  const [lista, setLista] = useState([]);

  useEffect(() => {
    if(props.distrito !== undefined && props.distrito !== null) {
      obtenerLista(props.distrito);
    }
  }, []);

  const obtenerLista = async (idDistrito) => {
    let params = {
      fields: 'id,nombre',
      ordenar: 'nombre.asc'
    };
    if(idDistrito !== undefined && idDistrito !== null && idDistrito !== "") {
      params['distrito'] = idDistrito;
    }
    Api.obtenerSecciones(params)
      .catch(error => {
        if(props.responseErrorCallback !== undefined) {
          props.responseErrorCallback(error);
        } 
      })
      .then(response => {
        if(response === undefined)
          return;
        
        if(response.resultado !== undefined && response.resultado.length) {
          setLista(response.resultado);
        }
      });
  };

  const opciones = () => {
    let opciones = [];
    for (let i in lista) {
      const seccion = lista[i];
      opciones.push({
        value: seccion.id,
        label: seccion.nombre
      });
    }
    return opciones;
  }

  return (
    <Select
      isMulti
      options={opciones()}
      {...props}
    />
  );
}

export default MultiSelectSeccion;
