import React, {useEffect, useState} from 'react';
import SelectWithSearch from 'react-select';

const SelectTipoMaterial = (props) => {

  const [lista, setLista] = useState([]);

  useEffect(() => {
    const array = [
      {value: 1, label: 'Insumo'},
      {value: 2, label: "Manual"},
    ];
    setLista(array);
  }, []);

  const opciones = () => {
    let opciones = [];
    for (let i in lista) {
      const tipo = lista[i];
      opciones.push({
        value: tipo.value,
        label: tipo.label
      });
    }
    return opciones;
  }

  lista.map(item=> {
    if(parseInt(item.value === props.defaultValue.value)){
      props.label = item.label;
    }
  });

  return (
    <SelectWithSearch
      label={props.label !== undefined && props.label !== null ? props.label : "Rol"}
      options={opciones()}
      placeholder={props.placeholder !== undefined && props.label !== null ? props.placeholder : "Seleccione..."}
      {...props}
    />
  );
}

export default SelectTipoMaterial;
