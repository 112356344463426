import React, { useEffect, useState, useContext } from "react";
import SelectWithSearch from "react-select";
import { ROL } from "../../../constants";
import Api from "../../../utils/Api";

const SelectRol = (props) => {
  const [lista, setLista] = useState([]);

  useEffect(() => {
    const getRoles = async () => {
      const resp = await Api.obtenerRoles();
      const roles = resp.resultado;
      let mapped = [];
      if (props.rol > ROL.ADMIN) {
        mapped = roles.filter((e) => e.id >= ROL.CALLCENTER);
      }
      mapped = roles.map((rol) => ({ value: rol.id, label: rol.nombre }));
      setLista(mapped);
    };
    if (lista.length == 0) {
      getRoles();
    }
  }, [lista]);

  const opciones = () => {
    let opciones = [];
    for (let i in lista) {
      const rol = lista[i];
      if (
        props.rol === undefined ||
        (props.rol !== undefined && rol.value > props.rol)
      ) {
        opciones.push({
          value: rol.value,
          label: rol.label,
        });
      }
    }
    return opciones;
  };

  lista.map((item) => {
    if (props.defaultValue) {
      if (parseInt(item.value === props.defaultValue.value)) {
        props.label = item.label;
      }
    }
  });

  return (
    <SelectWithSearch
      menuShouldScrollIntoView={true}
      value={props.defaultValue}
      label={
        props.label !== undefined && props.label !== null ? props.label : "Rol"
      }
      options={opciones()}
      placeholder={
        props.placeholder !== undefined && props.label !== null
          ? props.placeholder
          : "Seleccione..."
      }
      {...props}
    />
  );
};

export default SelectRol;
