import React, { useState, useEffect, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import PerfilContext from "../context/perfil/perfilContext";
import firebase from "../firebase";

const RouteLayout = ({ component: Component, ...rest }) => {
  const { perfil } = useContext(PerfilContext);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = firebase.auth();
    if (rest.requireAuth) {
      auth.onAuthStateChanged((user) => {
        setUser(user);
        setLoading(false);
      });
    } else {
      setUser(false);
    }
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        const {
          correo,
          celular,
          nombre,
          apellidoPaterno,
          apellidoMaterno,
          claveElector,
        } = perfil;
        console.log("rendering: ", perfil);
        if (!user) {
          matchProps.history.push("/login");
        } else if (
          user &&
          window.location.pathname !== "/auth/perfil" &&
          (!correo ||
            correo === "" ||
            !celular ||
            celular === "" ||
            !nombre ||
            nombre === "" ||
            !apellidoPaterno ||
            apellidoPaterno === "" ||
            !apellidoMaterno ||
            apellidoMaterno === "" ||
            !claveElector ||
            claveElector === "")
        ) {
          console.log("redireccionando");
          return <Redirect to={"/auth/perfil"} />;
        }
        return <Component {...matchProps} />;
      }}
    />
  );
};

export default RouteLayout;
