import { createMuiTheme } from "@material-ui/core/styles";
export default createMuiTheme({
  palette: {
    primary: {
      main: "#3E9BBC",
    },
    secondary: {
      main: "#6CBE45",
    },
  },
  overrides: {
    MuiButton: {
      containedSecondary: {
        color: "#ffffff",
      },
    },
  },
});
