import React, { useReducer } from 'react';
import perfilContext from './perfilContext';
import perfilReducer from './perfilReducer';
import { SET_PERFIL } from './perfilTypes';

const PerfilState = ({ children }) => {
  const initialState = {
    perfil: null
  };

  const [state, dispatch] = useReducer(perfilReducer, initialState);

  const setPerfil = perfil => {
    dispatch({
      type: SET_PERFIL,
      payload: perfil
    });
  }

  return (<perfilContext.Provider value={{perfil: state.perfil, setPerfil}}>{children}</perfilContext.Provider>);
}

export default PerfilState;
