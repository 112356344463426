import React from "react";
import classNames from "classnames";
import PerfilContext from "../../context/perfil/perfilContext";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import logo from "./../../assets/images/logo.png";
import {
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Divider,
} from "@material-ui/core";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import {
  SupervisedUserCircle as SolicitudIcon,
  ExpandLess,
  ExpandMore,
  Folder as FolderIcon,
  People as PeopleAlt,
  FolderOpen as FolderOpenIcon,
  Assignment,
  InsertDriveFile,
  CalendarToday as CalendarIcon,
  Person as PersonIcon,
  TvOff as TvOffIcon,
  Help as HelpIcon,
  CloudDownload as CloudDownloadIcon,
  BarChart as BarChartIcon,
  Map as MapIcon,
  HowToVote as VoteIcon,
  GroupWork as GroupWorkIcon,
  Announcement as AnnouncementIcon,
  FormatListNumberedRounded,
  Public as PublicIcon,
  Group as GroupIcon,
  DoneAll as DoneIcon,
  Phone,
  Error,
  Search,
  Sync,
  TurnedInNot,
  Room,
  AssignmentInd,
  Event,
  ViewModule,
  RecordVoiceOver,
  ShowChart,
  HowToVote,
  HowToReg,
  ImportExport,
  GraphicEq,
} from "@material-ui/icons";

import { ROL } from "../../constants";
import Api from "../../utils/Api";
import { auth } from "firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: "#7C2629",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 0,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    textDecoration: "none",
    paddingTop: 4,
    paddingBottom: 4,
    color: "rgba(255, 255, 255, 0.7)",
  },
  itemCategory: {
    backgroundColor: "#7a7a7a",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: 16,
    paddingBottom: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemActiveItem: {
    color: "#7a7a7a",
  },
  itemPrimary: {
    color: "inherit",
    fontSize: theme.typography.fontSize,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  perfil: {
    color: "#ffffff",
    display: "block",
    fontSize: "14px",
    minWidth: "inherit !important",
    padding: "0 4%",
    textDecoration: "none",
    textAlign: "center",
    marginTop: "-30px",
  },
  navigation: {
    color: "#ffffff",
    display: "block",
    fontSize: "14px",
    minWidth: "inherit !important",
    padding: "0 4%",
    fontWeight: "400",
    textTransform: "capitalize",
    textDecoration: "none",
    borderRadius: "0",
  },
  subNavigation: {
    color: "#ffffff",
    display: "block",
    fontSize: "14px",
    minWidth: "inherit !important",
    padding: "0 14%",
    fontWeight: "400",
    textTransform: "capitalize",
    textDecoration: "none",
    borderRadius: "0",
  },
  userProfile: {
    backgroundColor: "#7C2629",
    padding: "13px 20px",
  },
  dFlex: {
    display: "flex !important",
  },
  flexRow: {
    flexDirection: "row !important",
    width: "100%",
  },
  alignItemsCenter: {
    alignItems: "center !important",
  },
  userDetail: {
    color: "#ffffff",
    cursor: "pointer",
    fontSize: ".875rem",
    fontWeight: "400",
    whiteSpace: "nowrap",
    paddingLeft: "13px",
  },
  userAvatar: {
    maxWidth: "100%",
    height: "auto",
    width: "50px",
    backgroundColor: "#000000",
    borderRadius: 0,
  },
});

let Navigator = (props) => {
  const { classes, ...other } = props;
  const { perfil } = React.useContext(PerfilContext);
  const [catalogo, setCatalogo] = React.useState(false);
  const [padron, setPadron] = React.useState(false);
  const [avance, setAvance] = React.useState(false);
  const [defe, setDefe] = React.useState(false);
  const [reporte, setReporte] = React.useState(false);
  const [enBrigada, setEnBrigada] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    let algo = "";
    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    const getGrupos = async () => {
      try {
        const resp = await Api.obtenerGrupo({ id: 4, expand: "usuarios" });
        const resp2 = await Api.obtenerGrupo({ id: 89, expand: "usuarios" });
        let miembros = [];
        if (resp.resultado && resp.resultado[0]) {
          miembros = resp.resultado[0].usuarios;
        }
        if (resp2.resultado && resp2.resultado[0]) {
          miembros = [...miembros, ...resp2.resultado[0].usuarios];
        }
        const ids = miembros.map((e) => e.uid);
        const myUid = auth().currentUser.uid;
        const inGrupo = ids.includes(myUid);
        console.log(inGrupo);
        setEnBrigada(inGrupo);
      } catch (e) {
        console.warn(e);
      }
    };
    getGrupos();
  }, []);

  const rol = (perfil && perfil.rol) || ROL.PROMOTOR;
  const esAdmin =
    perfil.rol === ROL.SOPORTE ||
    perfil.rol === ROL.ADMIN ||
    perfil.rol === ROL.CANDIDATO ||
    perfil.rol === ROL.COORDINADOR_GENERAL;
  return (
    <Drawer
      variant="permanent"
      {...other}
      style={{ backgroundColor: "#7C2629 !important" }}
    >
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
        style={{ backgroundColor: "#7C2629" }}
      >
        <ListItem>
          <img
            alt="..."
            src={logo}
            style={{ width: "70%", height: "auto", margin: "0 10%" }}
          />
        </ListItem>

        <ListItem>
          <ListItemText
            classes={{ primary: classes.itemPrimary }}
            style={{ color: "white" }}
          >
            <Link key="perfil" to="/auth/perfil" className={classes.perfil}>
              {" "}
              {perfil.nombre === "" ? perfil.correo : perfil.nombre} <br />{" "}
              Perfil
            </Link>
          </ListItemText>
        </ListItem>

        <ListItem>
          <Divider />
        </ListItem>

        {/* {perfil.rol <= 2 && (
          <Link
            key="importador"
            to="/visita/importar"
            className={classes.navigation}
          >
            <ListItem button>
              <ListItemIcon>
                <ImportExport />
              </ListItemIcon>
              <ListItemText primary="Importar" />
            </ListItem>
          </Link>
        )} */}

        {(rol <= 4 ||
          perfil.correo === "heriberto.secregral@gmail.com"
          || perfil.correo === "Chemeljm@yahoo.com.mx") && (
            <Link
              key="estadistica"
              to="/estadistica"
              className={classes.navigation}
            >
              <ListItem button>
                <ListItemIcon>
                  <GraphicEq />
                </ListItemIcon>
                <ListItemText primary="Estadistica" />
              </ListItem>
            </Link>
          )}

        {esAdmin && perfil.permisos && (perfil.permisos.Comite & 1) === 1 && (
          <>
            {/* <Link key="totalizador" to="/" className={classes.navigation}>
                <ListItem button>
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Totalizador" />
                </ListItem>
              </Link> */}
            <Link key="comites" to="/" className={classes.navigation}>
              <ListItem button>
                <ListItemIcon>
                  <GroupWorkIcon />
                </ListItemIcon>
                <ListItemText primary="Comités" />
              </ListItem>
            </Link>
          </>
        )}

        {perfil.rol <= ROL.COORDINADOR_LOCAL && (
          <Link key="comites" to="/comites" className={classes.navigation}>
            <ListItem button>
              <ListItemIcon>
                <GroupWorkIcon />
              </ListItemIcon>
              <ListItemText primary="Comités Estadísticas" />
            </ListItem>
          </Link>
        )}

        {rol !== ROL.COORDINADOR_FEDERAL &&
          !esAdmin &&
          rol < ROL.PROMOTOR &&
          enBrigada &&
          perfil.permisos &&
          (perfil.permisos.Comite & 1) === 1 && (
            <Link key="comites" to="/" className={classes.navigation}>
              <ListItem button>
                <ListItemIcon>
                  <GroupWorkIcon />
                </ListItemIcon>
                <ListItemText primary="Comités" />
              </ListItem>
            </Link>
          )}

        {perfil.permisos && rol !== 9 && (perfil.permisos.Buscador & 1) === 1 && (
          <Link key="buscador" to="/buscador" className={classes.navigation}>
            <ListItem button>
              <ListItemIcon>
                <Search />
              </ListItemIcon>
              <ListItemText primary="Buscador" />
            </ListItem>
          </Link>
        )}

        {(rol <= 6 || perfil.rol === 9) && enBrigada && (
          // (perfil.permisos.Avances & 1) === 1 && (
          <Grid className={classes.navigation}>
            <ListItem button onClick={() => setAvance(!avance)}>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="Avances" />
              {avance ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Grid>
        )}
        {(rol <= 6 || perfil.rol === 9) && enBrigada && (
          <Collapse in={avance} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(perfil.rol <= 5 || perfil.rol === 9) && (
                <Link
                  key="dashboard"
                  to="/dashboard/comite"
                  className={classes.subNavigation}
                >
                  <ListItem button>
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary="Comité" />
                  </ListItem>
                </Link>
              )}
              {(perfil.rol <= 6 || perfil.rol === 9) && (
                <Link
                  key="convencidos"
                  to={"/dashboard/convencidos"}
                  className={classes.subNavigation}
                >
                  <ListItem button>
                    <ListItemIcon>
                      <DoneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Convencidos" />
                  </ListItem>
                </Link>
              )}
              {/* {perfil.rol !== ROL.COORDINADOR_FEDERAL &&
                perfil.permisos &&
                (perfil.permisos.AvanceConvencidos & 1) === 1 && (
                  <Link
                    key="convencidos"
                    to="/dashboard/visitas-grupo"
                    className={classes.subNavigation}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <DoneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Registros" />
                    </ListItem>
                  </Link>
                )} */}
              {/* {perfil.rol !== ROL.COORDINADOR_FEDERAL &&
                perfil.permisos &&
                (perfil.permisos.AvanceConvencidos & 1) === 1 && (
                  <Link
                    key="convencidos"
                    to="/dashboard/comites-grupo"
                    className={classes.subNavigation}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <DoneIcon />
                      </ListItemIcon>
                      <ListItemText primary="Comités agrupados" />
                    </ListItem>
                  </Link>
                )} */}
              {/* {perfil.rol <= 4 && (
                <>
                  {perfil.permisos &&
                    (perfil.permisos.AvanceRedes & 1) === 1 && (
                      <Link
                        key="redes"
                        to="/dashboard/avance-redes"
                        className={classes.subNavigation}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <PublicIcon />
                          </ListItemIcon>
                          <ListItemText primary="REDES" />
                        </ListItem>
                      </Link>
                    )}
                  {perfil.permisos &&
                    (perfil.permisos.AvanceDefensaVoto & 1) === 1 && (
                      <Link
                        key="voto"
                        to="/dashboard/avance-votos"
                        className={classes.subNavigation}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <VoteIcon />
                          </ListItemIcon>
                          <ListItemText primary="Defensa VOTO" />
                        </ListItem>
                      </Link>
                    )}
                </>
              )} */}
            </List>
          </Collapse>
        )}

        {false && rol < ROL.PROMOTOR && (
          <Link
            key="convencidos"
            to="/dashboard/convencidos"
            className={classes.navigation}
          >
            <ListItem button>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="Convencidos" />
            </ListItem>
          </Link>
        )}
        {/* { ((rol <= ROL.ADMIN ) || (rol == ROL.COORDINADOR_GENERAL ) ) && (
          <Link key="logs" to="/logs" className={classes.navigation}>
            <ListItem button>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText primary="Reporte del Día"/>
            </ListItem>
          </Link>
        )} */}
        {/* {(esAdmin || rol === 5 || rol === 6) &&
          perfil.permisos &&
          (perfil.permisos.Reporte & 1) === 1 && (
            <Grid className={classes.navigation}>
              <ListItem
                button
                onClick={() => setReporte(reporte ? false : true)}
              >
                <ListItemIcon>
                  {reporte ? <FolderOpenIcon /> : <FolderIcon />}
                </ListItemIcon>
                <ListItemText primary="Reportes" />
                {reporte ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </Grid>
          )} */}

        {(perfil.rol <= 6 || perfil.correo !== "Chemeljm@yahoo.com.mx" && perfil.rol === 9) && (
          <Link
            key="generalVisita"
            to="/reportes"
            className={classes.navigation}
          >
            <ListItem button>
              <ListItemIcon>
                <FolderOpenIcon />
              </ListItemIcon>
              <ListItemText primary="Reportes" />
            </ListItem>
          </Link>
        )}

        {/* <Collapse in={reporte} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {perfil.rol <= 6 &&
              perfil.permisos &&
              (perfil.permisos.ReporteVisitaDia & 1) === 1 && (
                <Link
                  key="visitas"
                  to="/reportes/visitas"
                  className={classes.subNavigation}
                >
                  <ListItem button>
                    <ListItemIcon>
                      <CalendarIcon />
                    </ListItemIcon>
                    <ListItemText primary="Visitas del día" />
                  </ListItem>
                </Link>
              )}

            {perfil.rol <= 4 &&
              perfil.permisos &&
              (perfil.permisos.ReporteSimpatizantes & 1) === 1 && (
                <Link
                  key="generalVisita"
                  to="/reportes/general"
                  className={classes.subNavigation}
                >
                  <ListItem button>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Simpatizantes" />
                  </ListItem>
                </Link>
              )}

            {perfil.rol <= 4 && (
              <Link
                key="generalVisita"
                to="/reportes/generar-visita"
                className={classes.subNavigation}
              >
                <ListItem button>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Visitas Grupo" />
                </ListItem>
              </Link>
            )}

            {perfil.rol <= 4 && (
              <Link
                key="generalVisita"
                to="/reportes/generar-comite"
                className={classes.subNavigation}
              >
                <ListItem button>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="Comités Grupo" />
                </ListItem>
              </Link>
            )}

            {perfil.rol <= 2 && (
              <Link
                key="generalVisita"
                to="/reportes/sin-terminar"
                className={classes.subNavigation}
              >
                <ListItem button>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary="VISITAS SIN TERMINAR" />
                </ListItem>
              </Link>
            )} */}

        {/* <Link key="visitas_manuales" to="/reportes/manuales" className={classes.navigation}>
              <ListItem button>
                <ListItemIcon>
                  <MapIcon />
                </ListItemIcon>
                <ListItemText primary="Reporte del Día Manual"/>
              </ListItem>
            </Link> */}
        {/* </List>
        </Collapse> */}

        {false && (
          <Link key="visita" to="/visitas" className={classes.navigation}>
            <ListItem button>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText primary="Visitas" />
            </ListItem>
          </Link>
        )}

        {esAdmin &&
          perfil.permisos &&
          (perfil.permisos.Simpatizantes & 1) === 1 && (
            <Link
              key="simpatizante"
              to="/simpatizantes"
              className={classes.navigation}
            >
              <ListItem button>
                <ListItemIcon>
                  <PeopleAlt />
                </ListItemIcon>
                <ListItemText primary="Simpatizantes" />
              </ListItem>
            </Link>
          )}

        {rol != ROL.PROMOTOR &&
          rol != ROL.CANDIDATO &&
          rol != ROL.CALLCENTER &&
          rol != ROL.REPORTES &&
          perfil.permisos &&
          (perfil.permisos.Usuario & 1) === 1 && (
            <Link key="usuarios" to="/usuarios" className={classes.navigation}>
              <ListItem button>
                <ListItemIcon>
                  <PeopleAlt />
                </ListItemIcon>
                <ListItemText primary="Usuarios" />
              </ListItem>
            </Link>
          )}

        {perfil.rol > 4 && (
          <Link
            key="estadistica-captura"
            to="/estadistica-captura"
            className={classes.navigation}
          >
            <ListItem button>
              <ListItemIcon>
                <Room />
              </ListItemIcon>
              <ListItemText primary="Capturas del dia" />
            </ListItem>
          </Link>
        )}

        {rol != ROL.REPORTES && (
          <Link key="visita" to="/visita" className={classes.navigation}>
            <ListItem button>
              <ListItemIcon>
                <Room />
              </ListItemIcon>
              <ListItemText primary="Capturas" />
            </ListItem>
          </Link>
        )}

        {rol != ROL.REPORTES && (
          <Link
            key="segunda-visita"
            to="/segunda-visita/nueva"
            className={classes.navigation}
          >
            <ListItem button>
              <ListItemIcon>
                <Room />
              </ListItemIcon>
              <ListItemText primary="Segunda visita" />
            </ListItem>
          </Link>
        )}

        {esAdmin &&
          perfil.rol !== ROL.CANDIDATO &&
          perfil.permisos &&
          (perfil.permisos.Administracion & 1) === 1 && (
            <>
              <Grid className={classes.navigation}>
                <ListItem
                  button
                  onClick={() => setPadron(padron ? false : true)}
                >
                  <ListItemIcon>
                    {padron ? <FolderOpenIcon /> : <FolderIcon />}
                  </ListItemIcon>
                  <ListItemText primary="Administración" />
                  {padron ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              </Grid>
              <Collapse in={padron} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {perfil.permisos &&
                    (perfil.permisos.AdministracionDistritosFederales & 1) ===
                      1 && (
                      <Link
                        key="distritos-federales"
                        to="/distritos-federales"
                        className={classes.subNavigation}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <PeopleAlt />
                          </ListItemIcon>
                          <ListItemText primary="Distritos Federales" />
                        </ListItem>
                      </Link>
                    )}
                  {perfil.permisos &&
                    (perfil.permisos.AdministracionDistritosLocales & 1) ===
                      1 && (
                      <Link
                        key="distritos-locales"
                        to="/distritos-locales"
                        className={classes.subNavigation}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <PeopleAlt />
                          </ListItemIcon>
                          <ListItemText primary="Distritos Locales" />
                        </ListItem>
                      </Link>
                    )}

                  {perfil.permisos &&
                    (perfil.permisos.AdministracionSecciones & 1) === 1 && (
                      <Link
                        key="seccion"
                        to="/secciones"
                        className={classes.subNavigation}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <PeopleAlt />
                          </ListItemIcon>
                          <ListItemText primary="Secciones" />
                        </ListItem>
                      </Link>
                    )}

                  {(rol == ROL.COORDINADOR_GENERAL ||
                    rol == ROL.COORDINADOR_FEDERAL ||
                    rol == ROL.SOPORTE ||
                    rol == ROL.ADMIN) &&
                    perfil.permisos &&
                    (perfil.permisos.AdministracionCambioINE & 1) === 1 && (
                      <Link
                        key="solicitudes"
                        to="/solicitudes"
                        className={classes.subNavigation}
                      >
                        <ListItem button>
                          <ListItemIcon>
                            <SolicitudIcon />
                          </ListItemIcon>
                          <ListItemText primary="Cambios INE" />
                        </ListItem>
                      </Link>
                    )}

                  {/* {perfil.permisos &&
                    (perfil.permisos.AdministracionEtiquetas & 1) === 1 && ( */}
                  <Link
                    key="etiquetas"
                    to="/grupos/etiquetas"
                    className={classes.subNavigation}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <TurnedInNot />
                      </ListItemIcon>
                      <ListItemText primary="Etiquetas" />
                    </ListItem>
                  </Link>
                  {/* )} */}
                </List>
              </Collapse>
            </>
          )}

        {rol != ROL.REPORTES &&
          perfil.permisos &&
          (perfil.permisos.Noticias & 1) === 1 && (
            <Link key="noticias" to="/noticias" className={classes.navigation}>
              <ListItem button>
                <ListItemIcon>
                  <AnnouncementIcon />
                </ListItemIcon>
                <ListItemText primary="Noticias" />
              </ListItem>
            </Link>
          )}

        {rol <= 4 && perfil.permisos && (perfil.permisos.Grupos & 1) === 1 && (
          <Link key="grupos" to="/grupos" className={classes.navigation}>
            <ListItem button>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Grupos" />
            </ListItem>
          </Link>
        )}

        {rol <= ROL.COORDINADOR_GENERAL &&
          perfil.permisos &&
          (perfil.permisos.Materiales & 1) === 1 && (
            <Link
              key="materiales"
              to="/materiales"
              className={classes.navigation}
            >
              <ListItem button>
                <ListItemIcon>
                  <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Materiales" />
              </ListItem>
            </Link>
          )}

        {(perfil.rol <= ROL.COORDINADOR_FEDERAL ||
          perfil.rol == ROL.CALLCENTER) &&
          perfil.permisos &&
          (perfil.permisos.Validacion & 1) === 1 && (
            <Link
              key="cc"
              to={perfil.rol == ROL.CALLCENTER ? "/" : "/cc"}
              className={classes.navigation}
            >
              <ListItem button>
                <ListItemIcon>
                  <ThumbsUpDownIcon />
                </ListItemIcon>
                <ListItemText primary="Validación" />
              </ListItem>
            </Link>
          )}

        {rol != ROL.REPORTES &&
          perfil.permisos &&
          (perfil.permisos.Ayuda & 1) === 1 && (
            <Link key="ayuda" to="/ayuda" className={classes.navigation}>
              <ListItem button>
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary="Ayuda" />
              </ListItem>
            </Link>
          )}

        {perfil.rol <= ROL.COORDINADOR_LOCAL &&
          perfil.permisos &&
          (perfil.permisos.ErroresVisita & 1) === 1 && (
            <Link
              key="error"
              to="/reportes/error"
              className={classes.navigation}
            >
              <ListItem button>
                <ListItemIcon>
                  <Error />
                </ListItemIcon>
                <ListItemText primary="Errores visitas" />
              </ListItem>
            </Link>
          )}
        {/* 
        <Link
          key="refresh"
          to="#"
          onClick={(_) => window.location.reload(true)}
          className={classes.navigation}
        >
          <ListItem button>
            <ListItemIcon>
              <Sync />
            </ListItemIcon>
            <ListItemText primary="Refrescar" />
          </ListItem>
        </Link> */}

        <Link key="close" to="/logout" className={classes.navigation}>
          <ListItem button>
            <ListItemIcon>
              <TvOffIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.itemPrimary }}>
              Cerrar Sesión
            </ListItemText>
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
};

export default withStyles(styles)(Navigator);
